@for (r of data; track r) {
  <oex-card
    [minHeight]="50"
    [collapsible]="true"
  >
    <!-- Header -->
    <div ngProjectAs="header" class="header">
      <span class="status"
            [class.success]="r.working_status == 1">{{ workingStatusText[r.working_status ?? ''] }}</span>Comments sent
      to the developer

      <!-- Date -->
      @if (r.last_updateUI) {
        <span class="date-txt">{{ r.last_updateUI }}</span>
      }
    </div>

    <!-- Details -->
    <div class="grid">

      <!-- Responsible -->
      <div>
        <label>Responsible</label>
        <a class="mp-link secondary"
           [routerLink]="'/user/' + r.moderator.name + '/' + r.moderator.individualKey">{{ r.moderator.name }}</a>
      </div>

      <!-- Release after report -->
      <div>
        <label>Release after report</label>
        <span class="badge"
              [class.badge-green]="r.release_after_report === 1">{{ r.release_after_report === 1 ? 'Yes' : 'No' }}</span>
      </div>

      <!-- Approved by admin -->
      <div>
        <label>Approved by admin</label>
        <span class="badge" [class.badge-green]="r.approved === 1">{{ r.approved === 1 ? 'Yes' : 'No' }}</span>
      </div>

      <!-- Documentation -->
      <div>
        <label>Documentation</label>
        <span class="badge"
              [class.badge-green]="r.documentationCheck === 1">{{ r.documentationCheck === 1 ? 'Yes' : 'No' }}</span>
      </div>

      <!-- Possibility to Start -->
      <div>
        <label>Possibility to Start</label>
        <span class="badge" [class.badge-green]="r.startCheck === 1">{{ r.startCheck === 1 ? 'Yes' : 'No' }}</span>
      </div>

      <!-- Possibility to Start -->
      <div>
        <label>Functionality</label>
        <span class="badge"
              [class.badge-green]="r.functionalityCheck === 1">{{ r.functionalityCheck === 1 ? 'Yes' : 'No' }}</span>
      </div>

      <!-- Comment -->
      <div class="col-3">
        <label>Comment</label>
        <div class="mp-markdown" [innerHTML]="r.commentMd"></div>
      </div>
    </div>
  </oex-card>
}
