@let dev = model();
@if (dev) {
  <!-- Location -->
  <div class="location">
    @if (dev.location.city || dev.location.country_code) {
      <img class="icon" ngSrc="assets/img/icon-location.svg" height="16" width="16">
      @if (dev.location.city) {
        <span>{{ dev.location.city }}</span>
      }
      @if (dev.location.country_code) {
        <span>{{ dev.location.country_code }}</span>
      }
    }
    <!-- Contact -->
    <a class="btn-primary btn-contact" (click)="showContactPopup()">Contact</a>
  </div>


  <!-- Rate -->
  <div class="rate">
    <img class="icon" ngSrc="assets/img/icon-bag-suitcase.svg" height="16" width="16">
    <span>$ {{ dev.salaryRate }} per hour, {{ dev.workTypeName }}</span>
  </div>

  <!-- Description -->
  <h4>I am available for</h4>
  <div [ui-markdown]="dev.info"></div>

  <!-- Certificates -->
  @if (dev.certificates.length) {
    <h4>Certified specialist</h4>
    @for (cert of dev.certificates; track cert) {
      <p>{{ cert }}</p>
    }
  }

  <!-- Skills -->
  <h4>Skills</h4>
  <div class="skill-list">
    @for (skill of dev.iscTechnology; track skill) {
      <span>{{ skill }}</span>
    }
    @for (skill of dev.skills; track skill) {
      <span>{{ skill }}</span>
    }
  </div>

  <!-- Contributions -->
  <h4>InterSystems Developer Ecosystem Contributions</h4>
  <oex-dev-contribution [developer]="dev" [showLinks]="true"></oex-dev-contribution>
}
