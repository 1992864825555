import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, viewChild } from '@angular/core';
import {ActivatedRoute, CanDeactivateFn, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {IPackageData} from '../../../services/packageTypes';
import {ITag} from '../../tags/tags-resolver';
import {HttpService} from '../../../services/httpService';
import {PartnersDirectoryService} from '../../../services/partnersDirectory';
import {ConfigService} from '../../../services/configService';
import {PackageDetailsResolver} from '../packageDetailsData';
import {AuthService} from '../../../services/authService';
import {ImageUploadComponent} from '../../../controls/image-upload/image-upload';
import {firstValueFrom} from 'rxjs/internal/firstValueFrom';
import {OEXMarkdown} from '../../../services/markdown';
import {HttpClient} from '@angular/common/http';
import * as Buf from 'buffer';
import {SaveModeChooseModalComponent} from '../saveModeChooseModal/saveModeChooseModal';
import {ModalData, ModalService} from '../../../services/modal.service';
import {BreadcrumbService} from '../../../services/breadcrumb.service';
import {IVersionHistoryEntry} from '../../versionHistory/versionHistory';
import {PublishModalComponent} from '../publishModal';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {NgSelectModule} from '@ng-select/ng-select';
import {PackageDetailsScreenComponent} from '../packageDetails';
import {isPlatformServer, NgTemplateOutlet} from '@angular/common';
import {DiffComponent} from '../../../controls/diff/diff.component';
import {FormsModule} from '@angular/forms';
import {MatTooltip} from '@angular/material/tooltip';
import {ShareButtonComponent} from '../../../controls/share-button/share-button.component';
import {ToastUIEditorComponent} from '../../../../oex-ui-kit/components/toastui-editor/toastui-editor.component';
import {PortalService} from '../../../portal/services/portal-service';
import {WarningComponent} from '../../../portal/warning/warning.component';
import {SiteTrackerService} from '../../../services/site-tracker.service';
import {STATUS_NAMES, TileBaseComponent} from '../../../controls/tile/tile.base';
import {TooltipDirective} from '../../../../oex-ui-kit/components/tooltip/tooltip.directive';
import {DialogService} from '../../../services/dialogService';
import {ProgressService} from '../../../../oex-ui-kit/services/progress.service';

export const packageEditorCanDeactivateResolver: CanDeactivateFn<PackageEditorComponent> = (
  comp: PackageEditorComponent
) => {
  if (comp.isUnsaved) {
    const answer = inject(DialogService).showDeactivateDialog();
    firstValueFrom(answer).then(a => comp.isUnsaved = !a);
    return answer;
  }
  return true;
}

@Component({
  selector: 'oex-pkg-editor',
  templateUrl: './package-editor.component.html',
  styleUrls: ['./../../../controls/tile/status.scss', './package-editor.component.scss'],
  imports: [
    PackageDetailsScreenComponent,
    WarningComponent,
    DiffComponent,
    ImageUploadComponent,
    MatCheckbox,
    FormsModule,
    NgTemplateOutlet,
    ToastUIEditorComponent,
    MatTooltip,
    NgSelectModule,
    ShareButtonComponent,
    ToastUIEditorComponent,
    TooltipDirective
  ],
  host: {
    '[class.disabled]': 'isDisabled'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageEditorComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);
  private http = inject(HttpService);
  auth = inject(AuthService);
  pd = inject(PartnersDirectoryService);
  st = inject(SiteTrackerService);
  private ngHttp = inject(HttpClient);
  private cd = inject(ChangeDetectorRef);
  private brs = inject(BreadcrumbService);
  private modal = inject(ModalService);
  private ps = inject(PortalService);
  private progress = inject(ProgressService);
  private router = inject(Router);
  private zone = inject(NgZone);
  private platformId = inject<Object>(PLATFORM_ID);

  readonly uploader = viewChild.required<ElementRef>('uploader');
  readonly imgUploader = viewChild.required<ImageUploadComponent>('imgUploader');

  model!: IPackageData;
  previewModel?: IPackageData;
  isNew = false;
  tags: string[] = [];
  actions = [...ConfigService.ACTIONS];
  categories = [...ConfigService.CATEGORIES];
  products = ConfigService.PRODUCTS.map(t => t.Name);
  industries = [...ConfigService.INDUSTRIES];
  previewImage = '';
  isUnsaved = false;
  isUploading = false;
  isPreview = false;

  private subDataChanged?: Subscription;
  private subOnBreadcrumb?: Subscription
  private originalModel!: IPackageData;
  private isLogoChanged = false;
  private approveVer = '';
  private approveNotes = '';

  get isHideOnPD() {
    const cat = (<any>this.categories).find(c => c.ID === this.model.CategoryID._id);
    if (!cat) {
      return false;
    }
    return cat.HideOnPD === 1;
  }

  get isOwner() {
    return this.isNew || this.auth.isAdmin || this.model?.UserID?.login === this.auth.getUser();
  }

  protected get isDisabled() {
    return !this.isOwner;
  }

  /**
   * Validate model
   * @param {object} m Model
   * @returns {boolean} True if valid
   */
  static validate(m): boolean {
    return !(!m.Name || !m.Description || !m.Tag?.length || !m.ISCTechnology || !m.Terms ||
      m.CategoryID._id === null ||
      m.CategoryID._id === undefined);
  }

  /**
   * Returns technology
   * @param listOrValue
   */
  static getValueFromList(listOrValue: string | string[]) {
    if (!listOrValue) {
      return;
    }
    if (typeof listOrValue === 'string') {
      return listOrValue;
    }
    return listOrValue.join(', ');
  }

  trackByIndex = (index: number, r: any) => index;

  ngOnInit() {
    this.subOnBreadcrumb = this.brs.onBreadcrumbPressed.subscribe(b => {
      if (b.id === 'back') {
        this.brs.setBreadcrumbs();
        this.isPreview = false;
        this.previewModel = undefined;
        this.cd.detectChanges();
      }
    });
    this.subDataChanged = this.route.data.subscribe((d) => {
      this.model = d.model;
      this.isNew = this.model._id === 'new';
      void this.requestTags();

      const byCompany = this.route.snapshot.queryParamMap.get('byCompany');
      if (byCompany) {
        this.model.ByCompany = byCompany;
      }

      // For invalid packages(no access, not exists, etc.) - navigate home
      if (this.model.invalid) {
        void this.router.navigateByUrl('/');
        return;
      }

      if (this.model.ISCTechnology && (typeof this.model.ISCTechnology === 'string')) {
        this.model.ISCTechnology = this.model.ISCTechnology.split(', ');
      }
      if (this.model.Industries && (typeof this.model.Industries === 'string')) {
        this.model.Industries = this.model.Industries.split(', ');
      }
      this.storeModel();
    });

    this.bindBeforeUnload();
    this.cd.detectChanges();
  }

  /**
   * Imports description from github
   */
  importDescription(url: string) {
    const gitUrl = url;
    if (!url) {
      return;
    }
    url = PackageDetailsResolver.getGithubAPIUrl(url);

    // Load repo details
    this.http.request(url, 'get')
      .then((data: any) => {
        try {
          this.model.Description = data.description || '';
          this.model.ProductURL = gitUrl;
          this.model.Name = data.name || '';
        } catch (e) {
        }
      })
      .catch(() => {
      })
      .finally(() => this.cd.detectChanges());
  }

  /**
   * Imports license url from git
   * @param url
   */
  importLicense(url: string) {
    if (!url) {
      return;
    }
    url = PackageDetailsResolver.getGithubAPIUrl(url);
    const isGitlab = url.toLowerCase().includes('gitlab.com');
    this.http.request(url + (isGitlab ? '?license=1' : '/license'), 'get')
      .then((data: any) => {
        this.model.Terms = data.html_url || data.license_url || '';
      })
      .catch(() => {
      })
      .finally(() => this.cd.detectChanges());
  }

  /**
   * Imports readme url from git
   * @param url
   */
  importReadme(url: string) {
    if (!url) {
      return;
    }
    url = PackageDetailsResolver.getGithubAPIUrl(url);
    const isGitlab = url.toLowerCase().includes('gitlab.com');
    this.http.request(url + (isGitlab ? '' : '/readme'), 'get')
      .then((data: any) => {
        this.model.Documentation = data.html_url || data.readme_url || '';
      })
      .catch(() => {
      })
      .finally(() => this.cd.detectChanges());
  }

  /**
   * On change for guthub field
   */
  onGithubChanged(url: string) {
    this.model.Action._id = '2';
    this.model.Action.Name = this.actions.find(a => a._id === this.model.Action._id)?.Name || '';
    this.importDescription(url);
    this.importLicense(url);
    this.importReadme(url);
  }

  onGitgubUrlBlur() {
    if (this.model.Github && !this.model.Github.toLowerCase().startsWith('http')) {
      this.model.Github = 'https://' + this.model.Github;
    }
  }

  onGitSponsorUrlBlur() {
    if (this.model?.SponsorshipURL && !this.model.SponsorshipURL.toLowerCase().startsWith('http')) {
      this.model.SponsorshipURL = 'https://' + this.model.SponsorshipURL;
    }
  }

  onActionUrlBlur() {
    if (this.model.ProductURL && !this.model.ProductURL.toLowerCase().startsWith('http')) {
      this.model.ProductURL = 'https://' + this.model.ProductURL;
    }
  }

  onDemoUrlBlur() {
    if (this.model.DemoURL && !this.model.DemoURL.toLowerCase().startsWith('http')) {
      this.model.DemoURL = 'https://' + this.model.DemoURL;
    }
  }

  onIdeasPortalUrlBlur() {
    if (this.model.IdeasPortalURL && !this.model.IdeasPortalURL.toLowerCase().startsWith('http')) {
      this.model.IdeasPortalURL = 'https://' + this.model.IdeasPortalURL;
    }
  }

  /**
   * Function needed to compare categories to get selected item in category select control
   * @param id1
   * @param id2
   */
  compareIds(id1: any, id2: any): boolean {
    const a1 = determineId(id1);
    const a2 = determineId(id2);
    return a1 === a2;
  }

  /**
   * Updates category name after selection
   */
  updateCategoryName() {
    this.isUnsaved = true;
    this.model.CategoryID.Name = (<any>this.categories).find(c => c.ID === this.model.CategoryID._id).Name;
  }

  updateActionName() {
    this.isUnsaved = true;
    this.model.Action.Name = this.actions.find(a => a._id === this.model.Action._id)?.Name || '';
  }

  getActionTypeText(m: IPackageData | undefined) {
    const a = this.actions.find(a => a._id === m?.Action?._id);
    if (a) {
      return a.Name;
    }
    return '';
  }

  getTechText(m: IPackageData | undefined): string {
    if (Array.isArray(m?.ISCTechnology)) {
      return m?.ISCTechnology.join(', ');
    } else {
      return m?.ISCTechnology || '';
    }
  }

  getIndText(m: IPackageData | undefined) {
    if (Array.isArray(m?.Industries)) {
      return m?.Industries.join(', ');
    } else {
      return m?.Industries || '';
    }
  }

  onLongDescChange() {
    this.isUnsaved = true;
    this.cd.detectChanges();
  }

  onLicenseUrlBlur() {
    if (this.model.Terms && !this.model.Terms.toLowerCase().startsWith('http')) {
      this.model.Terms = 'https://' + this.model.Terms;
    }
  }

  onArticleUrlBlur(idx: number) {
    if (this.model.DCURLs[idx] && this.model.DCURLs[idx].url && !this.model.DCURLs[idx].url.toLowerCase().startsWith('http')) {
      this.model.DCURLs[idx].url = 'https://' + this.model.DCURLs[idx].url;
    }
  }

  onVideoUrlBlur(idx: number) {
    if (this.model.VideoURLs[idx] && this.model.VideoURLs[idx].url && !this.model.VideoURLs[idx].url?.toLowerCase().startsWith('http')) {
      this.model.VideoURLs[idx].url = 'https://' + this.model.VideoURLs[idx].url;
    }
  }

  onDocUrlBlur() {
    if (this.model.Documentation && !this.model.Documentation.toLowerCase().startsWith('http')) {
      this.model.Documentation = 'https://' + this.model.Documentation;
    }
  }

  onSupportUrlBlur() {
    if (this.model.Support && !this.model.Support.toLowerCase().startsWith('http')) {
      this.model.Support = 'https://' + this.model.Support;
    }
  }

  addVideoUrl() {
    this.model.VideoURLs.push({url: ''});
  }

  addDCUrl() {
    this.model.DCURLs.push({url: ''});
  }

  deleteVideo(idx: number) {
    this.model.VideoURLs.splice(idx, 1);
  }

  deleteDCUrl(idx: number) {
    this.model.DCURLs.splice(idx, 1);
  }

  onLogoUploaderChanged() {
    this.isUnsaved = true;
    this.isLogoChanged = true;
  }

  startUpload(e: any) {
    this.isUploading = true;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      this.uploader().nativeElement.value = null;
      const formData = new FormData();
      formData.append('image', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', this.http.getUrl(`/mpapi/packages/image/${this.model._id}`), true);
      xhr.upload.onloadend = _ => {
        this.isUploading = false;
      };
      xhr.upload.onerror = _ => {
        this.isUploading = false;
      };
      xhr.onreadystatechange = () => {
        // tslint:disable-next-line:triple-equals
        if (xhr.readyState == 4) {
          this.isUploading = false;
          let url = '';
          try {
            url = JSON.parse(xhr.responseText).url;
          } catch (e) {
          }
          if (url) {
            this.model.ImageURL = url;
          }
        }
      };
      xhr.send(formData);
    };
    reader.readAsBinaryString(file);
  }

  /**
   * Starts preview of package
   */
  preview() {
    this.previewImage = this.imgUploader().image() || ''; //['changingThisBreaksApplicationSecurity'];
    this.ps.setProgress(true);
    this.loadReadme().finally(() => {
      PackageDetailsResolver.parseGithubReadme(this.model);
      this.isPreview = true;
      this.previewModel = JSON.parse(JSON.stringify(this.model));
      if (this.previewModel) {
        this.previewModel.ImageURL = this.imgUploader().image() || '';
        if (!this.previewModel.UserID?._id) {
          this.previewModel.UserID = {
            _id: '',
            avatar: this.auth.getUserAvatar(),
            firstName: this.auth.getUserName(),
            login: this.auth.getUser(),
            individualKey: this.auth.getUserKey()
          };
        }
      }
      this.ps.setProgress(false);
      this.cd.detectChanges();
    });
  }

  /**
   * Loads readme from github
   */
  loadReadme() {
    return new Promise((res: any, rej) => {
      let url = this.model.Github;

      if (!url) {
        res();
        return;
      }

      // Generate url for readme
      url = PackageDetailsResolver.getGithubAPIUrl(url);
      const isGitlab = url.toLowerCase().includes('gitlab.com');

      if (isGitlab) {
        this.loadGitlabReadme(url, res);
      } else {
        this.loadGithubReadme(url, res);
      }
    });
  }

  /**
   * Checks if user can publish
   */
  canPublish(): boolean {
    return (this.isNew || /*|| (this.model.UserID && this.auth.getUser() === this.model.UserID.login) && */
      (!this.isNew && (!this.model.Published && !this.model.NeedApprove)) || this.isUnsaved);
  }

  /**
   * Ask for app submission under company or not
   */
  askForSaveMode(doApprove = false) {
    const companies = this.auth.getUserCompanies();
    const companyExists = companies && companies[0];
    if (!companyExists && this.pd.enabled) {
      this.modal.show('You should belong to a company to create product');
      return;
    }
    // For `PD` always save by company (#828)
    if (!this.auth.isClaimer && this.pd.enabled && companies[0]) {
      this.model.ByCompany = companies[0].id?.toString();
      void this.save(doApprove);
      return;
    }

    if (this.route.snapshot.queryParamMap.get('byCompany') !== null) {
      void this.save(doApprove);
      return;
    }

    // Validation
    if (!this.validate()) {
      return;
    }

    // For OEX always save by user
    if (!this.pd.enabled) {
      this.model.ByCompany = undefined;
      void this.save(doApprove);
      return;
    }

    // Show modal
    const modal = this.modal.show({
      compModel: {
        component: SaveModeChooseModalComponent,
        styles: {nativeParent: [{className: 'center-buttons'}]},
        props: {
          mode: this.model?.PublisherID?._id ? '1' : '0'
        }
      },
      message: '',
      buttons: [
        {
          text: 'Save', default: true, clickOnEnter: true, close: true, click: (conf, comp) => {
            switch (comp.mode) {
              case '0': {
                this.model.ByCompany = undefined;
                break;
              }
              case '1': {
                this.model.ByCompany = comp.selectedCompany;
                break;
              }
              case '2': {
                const data = this.getSaveData(this.model);
                void this.saveData(data, false, doApprove);
                /*.then(resp => {
                  void this.router.navigateByUrl(
                    `/profile/${this.auth.getUserName()}/${this.auth.getUserKey()}?tab=company&isNewCompany=1&addServiceAfterCreation=1&addSolution=${resp._id}`
                  );
                });*/
                return;
              }
            }

            this.save(doApprove);
          }
        },
        {text: 'Cancel', close: true}
      ]
    });
  }

  /**
   * Save package data to server
   */
  save(doApprove = false) {
    const m = this.model;

    if (!this.validate()) {
      return;
    }

    // Otherwise save data
    const data = this.getSaveData(m);
    return this.saveData(data, false, doApprove);
  }

  saveData(data: any, ignoreNavigation = false, doApprove = false): Promise<any> {
    this.ps.setProgress(true);

    return this.http.request(
      '/mpapi/packages/save',
      'post',
      data)
      .then(result => {
        const id = result._id;
        this.model._id = result._id;
        this.isUnsaved = false;
        const onComplete = (img?) => {

          if (img !== undefined) {
            this.model.ImageURL = img;
          }

          if (doApprove) {
            this.modal.show('Your application was sent for approval successfully!');
          } else {
            this.modal.show('Your application was saved successfully!');
          }

          this.ps.setProgress(false);
          this.isUnsaved = false;
          if (this.isNew) {
            this.isNew = false;
            this.model.UserID = {login: this.auth.getUser()};
            void this.router.navigateByUrl('/portal/products/' + result.NameWithoutSpaces + '/edit');
            this.cd.detectChanges();
            return;
          }
          if (!doApprove) {
            this.model.NeedApprove = result.NeedApprove;
            this.model.Published = result.Published;
          }
          this.cd.detectChanges();
          return result;

          /*const nameWithoutSpaces = result.NameWithoutSpaces;
          if (this.model.NameWithoutSpaces !== nameWithoutSpaces) {
            if (!ignoreNavigation) {
              if (this.st.isPortal) {
                void this.router.navigateByUrl(`/portal/package/${nameWithoutSpaces}?edit=1`);
              } else {
                void this.router.navigateByUrl(`/package/${nameWithoutSpaces}`);
              }
            }
          } else {
            if (!this.model.PublisherID && this.model.ByCompany) {
              const comp = this.auth.getUserCompanyById(this.model.ByCompany);
              this.model.PublisherID = {NameWithoutSpaces: comp.nws, Name: comp.name};
            }
            if (this.model.PublisherID && !this.model.ByCompany) {
              this.model.PublisherID = null;
            }
          }
          PackageDetailsResolver.parseGithubReadme(this.model);
          this.cd.detectChanges();
          return result;*/
        };

        const checkApprove = (img?) => {
          if (doApprove) {
            this.model._id = result._id;
            this.approveReleaseNotes(this.approveVer, this.approveNotes)
              .then(() => {
                return onComplete(img);
              });
          } else {
            return onComplete(img);
          }
        };

        if (this.isLogoChanged) {
          return this.imgUploader().startUpload(`/mpapi/packages/image/${id}`)
            .then(img => {
              this.isLogoChanged = false;
              return checkApprove(img);
            });
        } else {
          return checkApprove();
        }
      })
      .catch(e => {
        this.showError(e);
        return false;
      })
      .finally(() => {
        this.ps.setProgress(false);
        this.cd.detectChanges();
      });
  }

  /**
   * Shows modal dialog with error message
   * @param {Exception} e Exception
   */
  showError(e: any) {
    try {
      const txt = e.error.summary.replace(/\?/g, '');
      this.modal.show(txt);
    } catch (er) {
      console.error(`Can't save package: `, e);
    }
  }

  /**
   * Sends app to approve
   * @param {object} d Data object with release notes and version
   */
  approveReleaseNotes(version, releaseNotes, modal?) {
    return this.http.request('/mpapi/packages/publish', 'post', {
      id: this.model._id,
      ReleaseNotes: releaseNotes,
      Version: version
    })
      .then(() => {
        this.model.NeedApprove = true;
        if (modal) {
          modal.component._modal.close();
        }
        this.cd.detectChanges();
      })
      .catch(e => {
        this.modal.show(this.http.getErrorText(e));
      });
  }

  validate(): boolean {
    if (!this.pd.enabled && (!this.model.Github && this.model.FullDescriptionGitCheck)) {
      this.modal.show('Please fill github/gitlab URL if you checked "Use GitHub/GitLab Readme as long description"');
      return false;
    }

    // Check required fields
    if (!PackageEditorComponent.validate(this.model)) {

      // Set touched for all required fields to show validation
      ConfigService.setTouchedToAllRequiredFields();

      this.modal.show('Please fill all required fields');
      return false;
    }

    if (this.model.DCURLs?.length) {
      for (let i = 0; i < this.model.DCURLs.length; i++) {
        const dc = this.model.DCURLs[i].url;
        if (dc && !dc.split('/')[2].toLowerCase().includes('community.intersystems.com')) {
          this.modal.show('Link to the article about this product should be on community.intersystems.com');
          return false;
        }
      }
    }

    // For PD package logo is required
    if (this.pd.enabled && !this.model.ImageURL && !this.imgUploader().isFileSelected()) {
      this.modal.show('Please choose package logo');
      return false;
    }

    return true;
  }

  /**
   * Returns data for server saving
   * @param {object} m Model
   * @returns {object} Data to save
   */
  getSaveData(m) {
    return {
      id: m._id,
      NeedApprove: false,
      Name: m.Name,
      CategoryID: {
        _id: m.CategoryID._id
      },
      Action: {
        _id: m.Action._id
      },
      Version: m.Version,
      Description: m.Description,
      DemoURL: m.DemoURL,
      FullDescription: m.FullDescription,
      FullDescriptionGitCheck: m.FullDescriptionGitCheck,
      Tag: (typeof m.Tag === 'string') ? m.Tag : (m.Tag || []).join(','),
      ISCTechnology: PackageEditorComponent.getValueFromList(m.ISCTechnology),
      Industries: PackageEditorComponent.getValueFromList(m.Industries),
      ImageURL: m.ImageURL,
      Github: m.Github,
      ProductURL: m.ProductURL,
      DCURLs: m.DCURLs.filter(url => !!url),
      ReleaseNotes: m.ReleaseNotes,
      Documentation: m.Documentation,
      CompanyURL: m.CompanyURL,
      Support: m.Support,
      IsInterSystemsSupported: m.IsInterSystemsSupported,
      Terms: m.Terms,
      VideoURLs: m.VideoURLs.filter(url => !!url),
      ByCompany: m.ByCompany,
      PublishPM: m.PublishPM || false,
      ShowOnOEX: (this.isHideOnPD ? true : m.ShowOnOEX) ? 1 : 0,
      ShowOnHIH: this.model.ShowOnHIH ? 1 : 0,
      PythonPackage: m.PythonPackage,
      SponsorshipURL: m.SponsorshipURL,
      IdeasPortalURL: m.IdeasPortalURL
    };
  }

  showUploadDialog() {
    this.uploader().nativeElement.click();
  }

  askForUnpublish() {
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: 'Are you sure you want to unpublish this package?',
      buttons: [
        {text: 'No', cancel: true},
        {
          text: 'Yes', default: true, cancel: true, click: _ => {
            this.unpublishPackage();
          }
        }
      ]
    });
  }

  askForCancelApproval() {
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: 'Are you sure you want to cancel approval?',
      buttons: [
        {text: 'No', cancel: true},
        {
          text: 'Yes', default: true, cancel: true, click: _ => {
            void this.cancelApproval();
          }
        }
      ]
    });
  }

  askForDeletion() {
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: 'Are you sure you want to delete this package?',
      buttons: [
        {text: 'No', cancel: true},
        {
          text: 'Yes', default: true, clickOnEnter: true, cancel: true, click: _ => {
            this.deletePackage();
          }
        }
      ]
    });
  }

  /**
   * Deletes package
   */
  deletePackage() {
    this.ps.setProgress(true);
    this.http.request(
      '/mpapi/packages/delete',
      'post',
      {id: this.model._id}
    )
      .then(data => {
        this.modal.show('Package was successfully deleted');
        void this.router.navigateByUrl('/portal/products');
      })
      .catch(e => this.showError(e))
      .finally(() => {
        this.ps.setProgress(false);
      });
  }

  /**
   * Cancel button handler
   */

  /*cancel(stayInEditMode = false) {
    this.isUnsaved = false;
    this.model = JSON.parse(JSON.stringify(this.originalModel));
    if (!stayInEditMode) {
      void this.router.navigateByUrl('/portal/products');
      return;
    }
  }*/

  /**
   * Unpublishes package
   */
  unpublishPackage() {
    this.ps.setProgress(true);
    this.http.request(
      '/mpapi/packages/unpublish',
      'post',
      {id: this.model.previousModel ? this.model.previousModel._id : this.model._id}
    )
      .then(data => {
        this.modal.show('Package was successfully unpublished');
        this.model.Published = false;
        if (this.model.previousModel) {
          this.model.previousModel.Published = false;
          this.model = JSON.parse(JSON.stringify(this.model.previousModel));
          this.model.previousModel = undefined;
        }
      })
      .catch(e => this.showError(e))
      .finally(() => {
        this.cd.detectChanges();
        this.ps.setProgress(false);
      });
  }

  /**
   * Checks if user can delete this package
   * Package can be deleted only in "draft" status
   * Admin can delete all packages
   * User can delete only his own
   */

  /*canDelete(): boolean {
    if (this.auth.isAdmin) {
      return true;
    }
    return this.model.UserID.login === this.auth.getUser();
  }*/

  askForApprovalBeforeSave() {
    if (!this.validate()) {
      return;
    }
    this.askForReleaseNotes((ver, notes) => {
      this.approveVer = ver;
      this.approveNotes = notes;
      this.askForSaveMode(true);
      // this.save(true);
    });
  }

  askForReleaseNotes(saveCallback?: (ver: string, releaseNotes: string) => void) {
    if (this.model?.FirstApprovalDate) {
      this.modal.show({
        message: 'Do you want to make a new release or just send description edits for approval?',
        compModel: {
          styles: {nativeParent: [{className: 'fix-width-460'}, {className: 'center-buttons'}]}
        },
        buttons: [
          {
            text: 'Release app', default: true, cancel: true, click: _ => {
              this.showReleaseNotesDialog(saveCallback);
            }
          },
          {
            text: 'Send edits', default: false, cancel: true, click: _ => {
              if (saveCallback) {
                saveCallback(this.model?.Version, this.model?.ReleaseNotes);
              } else {
                this.approveReleaseNotes(this.model?.Version, this.model?.ReleaseNotes);
              }
              // this.showReleaseNotesDialog(true);
            }
          },
          {text: 'Cancel', cancel: true},
        ]
      });
      return;
    }
    this.showReleaseNotesDialog(saveCallback);
  }

  /**
   * Show modal with version and release notes inputs
   * @returns {Promise<any>}
   */
  showReleaseNotes(v: IVersionHistoryEntry | null, title: string, okButtonText: string, onSave: (m?: ModalData) => void) {
    this.http.request('/mpapi/user/getCurrent')
      .then((u) => {
        const m = this.modal.show(<ModalData>{
          title,
          compModel: {
            component: PublishModalComponent,
            props: {
              packageModel: this.model,
              showActions: false
            },
            styles: {nativeParent: [{className: 'right-buttons'}, {className: 'width100mobile'}]}
          },
          message: '',
          buttons: [
            {text: 'Close', cancel: true},
            {
              text: okButtonText, default: true, click: _ => {
                onSave(m);
              }
            }
          ]
        });
      });
  }

  showReleaseNotesDialog(saveCallback?: (ver: string, releaseNotes: string) => void) {
    this.showReleaseNotes(null, 'Send for approval', 'Send', modal => {
      const version = modal?.component.version().ver;
      const releaseNotes = modal?.component.version().notes;
      if (!version || !releaseNotes) {
        this.modal.show('Please fill all fields');
        return;
      }
      modal?.component.clearNotes();

      if (saveCallback) {
        saveCallback(version, releaseNotes);
        modal?.component._modal.close();
      } else {
        void this.approveReleaseNotes(version, releaseNotes, modal);
      }
      // this.onReleaseNotesCommitted.emit();
    });
  }

  ngOnDestroy() {
    this.unbindBeforeUnload();
    this.subOnBreadcrumb?.unsubscribe();
    this.subDataChanged?.unsubscribe();
  }

  onAIChange(e: MatCheckboxChange, isAI = true) {
    let tag = 'AI - Artificial Intelligence';
    if (!isAI) {
      tag = 'ML - Machine Learning';
    }
    if (e.checked) {
      if (!this.model.Tag.includes(tag)) {
        (this.model.Tag as string[]).push(tag);
      }
    } else {
      const idx = (this.model.Tag as string[]).indexOf(tag);
      if (idx !== -1) {
        (this.model.Tag as string[]).splice(idx, 1);
      }
    }
    this.model.Tag = [...this.model.Tag];
  }

  getStatus() {
    if (this.model?.previousModel) {
      return TileBaseComponent.getStatus(this.model?.previousModel?.Published, this.model?.previousModel?.NeedApprove);
    }
    return TileBaseComponent.getStatus(this.model?.Published, this.model?.NeedApprove);
  }

  getStatusText() {
    const status = this.getStatus();
    return STATUS_NAMES[status];
  }

  private requestTags(): Promise<any> {
    const url = `/mpapi/packages/tags`;

    // Request company data
    return this.http.request(url)
      .then((tags: ITag[]) => {
        this.tags = tags.map(t => t.tag);
      });
  }

  /**
   * Store original model, to be able restore it after cancel
   */
  private storeModel() {
    // this.originalModel = {};
    this.originalModel = JSON.parse(JSON.stringify(this.model));
    this.model.ImageURLTemp = this.model.ImageURL;
  }

  private loadGithubReadme(url: string, res) {
    // Load readme
    const pReadme = new Promise((rres, rrej) => {
      this.http.request(url + '/readme', 'get')
        .then((data: any) => {
          this.ngHttp.get(data.url, {responseType: 'text'})
            .toPromise()
            .then((readme: any) => {
              try {
                const r = JSON.parse(readme);
                const result = OEXMarkdown.parseGit(Buf.Buffer.from(r.content, 'base64').toString('utf-8') || '');
                this.model.FullDescriptionMd = result;
              } catch {
              }
              res();
            })
            .catch(e => {
              console.warn(e);
              res();
            });
        })
        .catch(() => res());
    });
  }

  private loadGitlabReadme(url: string, res: any) {
    this.http.request(url, 'get')
      .then((data: any) => {
        const fileName = data.readme_url?.split('/').pop();
        if (!fileName) {
          return;
        }
        firstValueFrom<any>(this.ngHttp.get(url + '/repository/files/' + fileName + '?ref=master', {responseType: 'text'}))
          .then((readme: any) => {
            try {
              const r = JSON.parse(readme);
              const result = OEXMarkdown.parseGit(Buf.Buffer.from(r.content, 'base64').toString('utf-8') || '');
              this.model.FullDescriptionMd = result;
            } catch {
            }
            res();
          })
          .catch(e => {
            console.warn(e);
            res();
          });
      })
      .catch(() => res());
  }

  private onBeforeUnload = (e: Event) => {
    if (this.isUnsaved) {
      e.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      e.returnValue = false;
      return false;
    }
    return true;
  }

  private bindBeforeUnload() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      window.addEventListener('beforeunload', this.onBeforeUnload);
    });
  }

  private unbindBeforeUnload() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  private async cancelApproval() {
    this.progress.show();
    try {
      await this.http.request('/mpapi/packages/close_approval', 'post', {id: this.model._id});
      this.model.NeedApprove = false;
      if (this.model?.previousModel) {
        this.model.previousModel.NeedApprove = false;
      }
      this.cd.detectChanges();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.progress.hide();
    }
  }
}

// This function needed due to issue with material select component. It sometimes stores selected item as array with single element
function determineId(id: any): string {
  if (!id) {
    return '';
  }
  if (id.constructor.name === 'array' && id.length > 0) {
    return '' + id[0];
  }
  return '' + id;
}
