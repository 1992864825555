import {ChangeDetectionStrategy, Component, inject, input, OnInit, signal} from "@angular/core";
import {ProgressService} from '../../../oex-ui-kit/services/progress.service';
import {APIService} from '../../services/api.service';
import {IDevProfile} from '../../services/api.types';
import {NgOptimizedImage} from '@angular/common';
import {DevContributionComponent} from '../../controls/dev-contribution/dev-contribution.component';
import {ModalService} from '../../services/modal.service';
import {MarkdownDirective} from '../../../oex-ui-kit/components/markdown/markdown.directive';

@Component({
  selector: 'oex-developer-details',
  templateUrl: './developer-details.component.html',
  styleUrls: ['./developer-details.component.scss'],
  imports: [
    NgOptimizedImage,
    DevContributionComponent,
    MarkdownDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeveloperDetailsComponent implements OnInit {
  profileId = input<number>();
  model = signal<IDevProfile|undefined>(undefined);
  developer = input<IDevProfile>();

  private api = inject(APIService);
  private ps = inject(ProgressService);
  private modal = inject(ModalService);

  ngOnInit() {
    if (this.developer()) {
      this.model.set(this.developer());
    } else {
      void this.requestData();
    }
  }

  private async requestData() {
    const id = this.profileId();
    if (id === undefined) {
      return;
    }
    this.ps.show();
    try {
      const profile = await this.api.getDevProfile(id);
      this.model.set(profile);
    } finally {
      this.ps.hide();
    }
  }

  showContactPopup() {
    void this.modal.showAsync({
      compModel: {
        component: import('./../../dialogs/contact-dev/contact-dev.component'),
        props: {
          developer: this.model()
        },
      },
      cancel: true,
      buttons: []
    });
  }
}
