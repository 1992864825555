@if (isPreview()) {
  @if (model(); as m) {
    <h3 class="ui-portal-title">Profile preview</h3>
    <oex-developer-details [developer]="m.profile"></oex-developer-details>

    <h3 class="ui-portal-title preview-card-title">Profile card</h3>
    <oex-dev-profile-card [developer]="m.profile"></oex-dev-profile-card>
  }
} @else {
  <!-- Title -->
  <h3 class="ui-portal-title">
    Profile Developer Marketplace
    <span
      [class]="'mp-status mp-tile-status-' + status().code"
    >{{ status().text }}</span>
  </h3>
  <!-- Form -->
  @if (model(); as m) {
    <div class="ui-form frm-2-cols">
      <!-- Title -->
      <div class="control required span-2x">
        <label>Work type</label>
        <div class="radio-list">
          @for (wt of m.workTypes; track wt.id) {
            <label>
              <input type="radio" [(ngModel)]="m.profile.workTypeId" [value]="wt.id">{{ wt.name }}
            </label>
          }
        </div>
      </div>

      <!-- Hourly rate -->
      <div class="control required">
        <label>Hourly rate $ per hour</label>
        <input type="number" [(ngModel)]="m.profile.salaryRate" required>
      </div>

      <!-- Location -->
      <div class="control">
        <label>Location</label>
        <!--      <input type="text" [(ngModel)]="m.profile.location.city">-->
        <ng-select
          class="margin-small"
          [items]="addr.address$ | async"
          bindLabel="text"
          [minTermLength]="2"
          [clearable]="true"
          [loading]="addr.isLoading()"
          [typeahead]="addr.input$"
          [(ngModel)]="m.profile.location"
        >
        </ng-select>
      </div>

      <!-- InterSystems technology -->
      <div class="control required span-2x">
        <label>InterSystems technology</label>
        <ng-select
          [(ngModel)]="m.profile.iscTechnology"
          [items]="ISC_TECHNOLOGY"
          [clearable]="false"
          [multiple]="true"
          [closeOnSelect]="false"
          required
        ></ng-select>
      </div>

      <!-- Other skills -->
      <div class="control span-2x">
        <label>Other Skills</label>
        <ng-select
          bindLabel="name"
          bindValue="name"
          [(ngModel)]="m.profile.skills"
          [items]="m.skills"
          [clearable]="false"
          [multiple]="true"
          [closeOnSelect]="false"
        ></ng-select>
      </div>

      <!-- Description -->
      <div class="control span-2x required">
        <label>What you can help with and how do you see a process</label>
        @defer (on viewport; prefetch on idle) {
          <ui-tui-editor
            initialEditType="wysiwyg"
            [(value)]="m.profile.info"
            [height]="186"
            required
          ></ui-tui-editor>
        } @placeholder {
          <div></div>
        }
      </div>

      <!-- Contact options -->
      <div class="control span-2x sub-title">
        <label>Contact options</label>
      </div>

      <!-- Linkedin -->
      <div class="control">
        <label>Linkedin</label>
        <input type="text" [(ngModel)]="m.profile.linkedURL" placeholder="https://" requiredAnyOf="1">
      </div>

      <!-- Twitter -->
      <div class="control">
        <label>Twitter</label>
        <input type="text" [(ngModel)]="m.profile.twitterURL" placeholder="https://" requiredAnyOf="1">
      </div>

      <!-- Upwork profile -->
      <div class="control">
        <label>Upwork profile</label>
        <input type="text" [(ngModel)]="m.profile.upworkURL" placeholder="https://" requiredAnyOf="1">
      </div>

      <!-- Consultation booking link -->
      <div class="control">
        <label>Consultation booking link</label>
        <input type="text" [(ngModel)]="m.profile.consultationURL" placeholder="https://" requiredAnyOf="1">
      </div>

      <!-- Send Direct Message via Developer Community -->
      <div class="control span-2x">
        <label>
          <input type="checkbox" [(ngModel)]="m.profile.dcdm">
          Send Direct Message via Developer Community
          <div class="ui-icon-info"></div>
        </label>
      </div>

      <!-- Contribution -->
      @if (m.profile.id) {
        <div class="control span-2x gap-2x">
          <label>We will also show your contribution and certifications:</label>
          <div class="contribution-block">
            @if (m.profile.certificates.length) {
              <!-- Certified -->
              <span class="cert">
              <img ngSrc="/assets/img/certified-icon.svg" height="16" width="16">
              Certified specialist&nbsp;&nbsp;
                @for (c of m.profile.certificates; track c) {
                  &nbsp;{{ c }}&nbsp;
                }
            </span>
            }
            <oex-dev-contribution [developer]="m.profile"></oex-dev-contribution>
          </div>
        </div>
      }
    </div>
  }
}

<!-- Actions -->
<div class="ui-portal-actions">
  @if (isPreview()) {
    <!-- Cancel preview -->
    <button class="btn-outline" (click)="cancelPreviewClick()">Cancel</button>
  } @else {
    <!-- Save button -->
    <button class="btn-primary" (click)="saveClick()" ui-validate>Save</button>
    <!-- Preview button -->
    <button class="btn-outline" (click)="previewClick()">Preview</button>
    <!-- Send for approval button -->
    <button class="btn-outline" (click)="sendForApprovalClick()" ui-validate>Send for approval</button>
  }
</div>
