import {ChangeDetectionStrategy, Component, computed, inject, OnInit, signal} from '@angular/core';
import {ActivatedRoute, CanActivateFn, ResolveFn} from '@angular/router';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/authService';
import {IDevDictionaryItem, IDevProfile} from '../../services/api.types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {ConfigService} from '../../services/configService';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ToastUIEditorComponent} from '../../../oex-ui-kit/components/toastui-editor/toastui-editor.component';
import {DevContributionComponent} from '../../controls/dev-contribution/dev-contribution.component';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {ValidationDirective} from '../../../oex-ui-kit/components/validation/validation.directive';
import {ModalService} from '../../services/modal.service';
import {ProgressService} from '../../../oex-ui-kit/services/progress.service';
import {AddressService} from '../../../oex-ui-kit/services/address.service';
import {DevProfileCardComponent} from '../../controls/dev-profile-card/dev-profile-card.component';
import {DeveloperDetailsComponent} from '../../pages/developer-details/developer-details.component';
import {STATUS_NAMES, TileBaseComponent} from '../../controls/tile/tile.base';
import {HttpService} from '../../services/httpService';

interface IDevProfileModel {
  certs: IDevDictionaryItem[];
  skills: IDevDictionaryItem[];
  workTypes: IDevDictionaryItem[];
  profile: IDevProfile
}

const EMPTY_PROFILE: any = {
  location: {},
  published: false,
  needApprove: false,
  iscTechnology: [],
  skills: [],
  certificates: []
}

export const canActivateDevProfileEditor: CanActivateFn = async () => {
  const auth = inject(AuthService);
  return auth.isProfileOwner;
};

const getEmptyProfile = async (auth: AuthService, api: APIService, http: HttpService) => {
  const u = auth.user;
  const p = await http.request(`/mpapi/user/get?name=${u?.name}&key=${u?.individualKey}`)

  return {
    ...structuredClone(EMPTY_PROFILE),
    userName: u?.name ?? '',
    userIndividualKey: u?.individualKey ?? '',
    rating: 0,
    userImage: p.avatar,
    dcProfileURL: p.DCProfile,
    info: p.description,
    twitterURL: p.urlTwitter,
    linkedURL: p.urlLinkedin,
    awards: p.rewards.length,
    location: {
      city: '',
      country: p.country,
      country_code: ''
    }
    // TODO: add company
  } as IDevProfile
};

export const resolveDeveloperProfile: ResolveFn<IDevProfileModel> = async () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  const id = auth.user?.profileDraft;
  //const id = undefined; //auth.user?.profileDraft;

  const [
    certs,
    skills,
    workTypes,
    profile
  ] = await Promise.all([
    api.getDevCertificates(),
    api.getDevSkills(),
    api.getDevWorkTypes(),
    id === undefined ? getEmptyProfile(auth, api, inject(HttpService)) : api.getDevProfile(id)
  ]);

  //profile.certificates = ['Test 1', 'fsdfdsfsdfsd fsdfsdfsd', 'Test 2'];

  return {
    certs,
    skills,
    workTypes,
    profile
  } as IDevProfileModel;
}

@Component({
  selector: 'app-developer-profile',
  templateUrl: './dev-profile-editor.html',
  styleUrls: ['../../controls/tile/status.scss', './dev-profile-editor.scss'],
  imports: [
    FormsModule,
    NgSelectComponent,
    ToastUIEditorComponent,
    DevContributionComponent,
    NgOptimizedImage,
    ValidationDirective,
    AsyncPipe,
    DevProfileCardComponent,
    DeveloperDetailsComponent
  ],
  providers: [AddressService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevProfileEditor implements OnInit {
  protected model = signal<IDevProfileModel | undefined>(undefined);
  protected isPreview = signal(false);
  protected ISC_TECHNOLOGY = ConfigService.PRODUCTS.map(t => t.Name);
  protected status = computed(() => {
    const p = this.model()?.profile;
    const status = {
      code: '',
      text: ''
    }
    if (!p) {
      return status;
    }
    status.code = TileBaseComponent.getStatus(p.published, p.needApprove);
    status.text = STATUS_NAMES[status.code];
    return status;
  })

  protected addr = inject(AddressService);
  private api = inject(APIService);
  private auth = inject(AuthService);
  private route = inject(ActivatedRoute);
  private ps = inject(ProgressService);
  private modal = inject(ModalService);
  private data$ = this.route.data.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.data$.subscribe(d => {
      this.model.set(d.model);
    });
  }

  protected async saveClick(doApprove = false) {
    if (!this.validate()) {
      this.modal.show('Please fill all required fields.');
      return;
    }

    try {
      await this.save();
      if (doApprove) {
        await this.approve();
      }
    } catch {
    }
  }

  protected previewClick() {
    this.isPreview.set(true);
  }

  protected cancelPreviewClick() {
    this.isPreview.set(false);
  }

  protected sendForApprovalClick() {
    void this.saveClick(true);
  }

  private validate() {
    const p = this.model()?.profile;
    if (!p) {
      return false;
    }
    return (p.workTypeId !== undefined) && p.salaryRate && p.iscTechnology.length && p.info && (p.linkedURL || p.twitterURL || p.upworkURL || p.consultationURL);
  }

  private async save() {
    const profile = this.model()?.profile;
    if (!profile) {
      return;
    }
    // Update location from autocompletion to the actual location object
    /*const loc = profile.location as any;
    if (loc.id) {
      profile.location = {
        country_code: loc.country_code,
        city: loc.city,
        country: loc.country,
      }
    }*/
    this.ps.show()
    try {
      const resp = await this.api.saveDevProfile(profile);
      if (resp.id !== undefined) {
        profile.id = resp.id;
      }
      this.updateUserProfileInState(resp.id);
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private async approve() {
    const profile = this.model()?.profile;
    if (!profile?.id) {
      return;
    }
    this.ps.show()
    try {
      await this.api.publishDevProfile(profile.id);
      profile.needApprove = true;
      this.model.update(m => structuredClone(m));
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private updateUserProfileInState(id?: number) {
    if (id === undefined || this.auth.user?.profile !== undefined) {
      return;
    }
    const usr = this.auth.user;
    if (!usr) {
      return;
    }
    usr.isProfileOwner = 1;
    usr.profile = id;
    usr.profileDraft = id;
  }
}
